import React from "react"

import SEO from "../components/seo"
import PageHeader from "../components/PageHeader"
import headerImage from "../images/slider/slide-2.jpg"
import { Button } from "../components/GlobalStyle"
import styled from "styled-components"

const StyledButtonWrap = styled.div`
  position: relative;
  z-index: 10; 
  margin-top: 20px;
`
const StyledHeaderWrap = styled.div`
  & > div {
    height: 100vh;
    background-position: center center;
    h1 {
      line-height: 1.1em;
    }
  }
`

const NotFoundPage = () => (
  <>
    <SEO title="404" />

    <StyledHeaderWrap>
      <PageHeader image={headerImage}>
        <h1 data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">Oops!<br/> Nie znaleziono strony, której szukasz</h1>
        <p>Strona, której szukasz nie istnieje.
          Powróć do istniejącej strony</p>
        <StyledButtonWrap>
          <Button to="/">Strona główna</Button>
        </StyledButtonWrap>
      </PageHeader>
    </StyledHeaderWrap>
  </>
)

export default NotFoundPage
