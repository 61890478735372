import React, { Component } from "react"
import styled from "styled-components"

const StyledHeader = styled.div`
  height: ${props => props.gallery ? '700px' : '500px'};
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center ${props => props.gallery ? 'bottom' : '-100px'};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  .gallery-text {
    max-width: 1100px;
    position: relative;
    top: 40px;
    opacity: 1;
    color: rgba(255,255,255,.8);
    font-size: 24px;
    line-height: 1.3em;
    text-shadow: 0 0 4px rgba(0,0,0,0.5);
    span {
      color: #fff;
    }
    @media(max-width: 1000px) {
      font-size: 15px;
      top: 30px;
      line-height: 1.3em;
    }
  }
  .gallery-header{
    position: relative;
    top: 30px;
    @media(max-width: 1000px) {
      font-size: 40px;
      top: 20px;
    }
  }
  @media (max-width: 1000px) {
    background-position: center 0;
    padding: 50px 10px 0;
    height: ${props => props.gallery ? '500px' : '350px'};
  }
  p {
    position: relative;
    z-index: 10;
    text-align: center;
    max-width: 500px;
    opacity: .7;
  }
  h1 {
    position: relative;
    z-index: 10;
    font-size: 4em;
    text-align: center;
    @media (max-width: 1000px) {
      font-size: 3.2em;
    }
    @media (max-width: 720px) {
      font-size: 2.2em;
      line-height: 1.1em;
    }
    @media (max-width: 520px) {
      font-size: 1.6em;
      line-height: 1.1em;
      margin-bottom: 10px;
    }
  }
  &:after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: 
      radial-gradient(transparent,#1e1b3a),
      linear-gradient(to right,rgba(33, 27, 86, 0.6), transparent),
      ${props => props.gallery ? 'linear-gradient(to top,rgba(33, 27, 86, 0.7) 40%, transparent)' : 'linear-gradient(to right, transparent, transparent)'};
      
  }
`
class PageHeader extends Component {
  render() {
    return (
      <StyledHeader gallery={this.props.gallery} image={this.props.image}>
        {this.props.children}
      </StyledHeader>
    )
  }
}
export default PageHeader
